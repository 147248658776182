import './App.css';

function App() {
  return (
    <section>
      <div className="logo-container">
        <img
          src="./logo-on-transparent.png"
          loading="lazy"
          sizes="(max-width: 479px) 94vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 94vw"
          srcSet="./logo-on-transparent-p-500.png 500w, ./logo-on-transparent-p-800.png 800w, ./logo-on-transparent-p-1080.png 1080w, ./logo-on-transparent-p-1600.png 1600w, ./logo-on-transparent.png 1710w"
          alt="logo" />
      </div>
      <div className="hero-wrapper">
        <div className="hero-split">
          <p>
            Demystifying stock options to help investors manage portfolio risk and
            increase returns.
          </p>
          <p>Contact us at <a href="mailto:info@karmahorizons.com">info@karmahorizons.com</a>
          </p>
        </div>
        <div className="hero-split">
          <img
            className="shadow"
            src="./artem-verbo-zHg5TXgVoGQ-unsplash.jpg"
            loading="lazy"
            sizes="(max-width: 479px) 96vw, (max-width: 991px) 266.65625px, 43vw"
            srcSet="./artem-verbo-zHg5TXgVoGQ-unsplash-p-500.jpg 500w, ./artem-verbo-zHg5TXgVoGQ-unsplash-p-800.jpg 800w, ./artem-verbo-zHg5TXgVoGQ-unsplash-p-1080.jpg 1080w, ./artem-verbo-zHg5TXgVoGQ-unsplash-p-1600.jpg 1600w, ./artem-verbo-zHg5TXgVoGQ-unsplash.jpg 1920w"
            alt="sailboat" />
        </div>
      </div>
    </section>
  )
}

export default App;
